var star = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.9 78.84" class="category-links__star"><path style="fill:inherit" d="m41.45 0 12.81 25.95 28.64 4.16-20.73 20.21 4.9 28.52-25.62-13.46-25.62 13.46 4.9-28.52L0 30.11l28.64-4.16L41.45 0z"/></svg>`;

function loadSliders() {
	$('.testimonials .carousel-inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 2,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.home-clients__gallery').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 5,
		dots: true,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});

}

var sliderScript = document.getElementById("deferredSlickScript");
if (sliderScript) {
	sliderScript.addEventListener('load', function () {
		loadSliders();
	});
};
function homepageAnimations() {
	$('.header__logo').addClass("zoomIn");
	$('.header__logo').css("visibility", "visible");

	$('.outer-wrapper__bg--left').addClass("bgLeft");
	$('.outer-wrapper__bg--right').addClass("bgRight");
	//$('.outer-wrapper__bg-inner').addClass("bgShift");

	$('.header__logo').on('mouseenter', function () {
		$('.outer-wrapper__bg-inner').addClass("bgShift");
	});


	$('.book-online__title-img').on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
		$(this).removeClass('heartBeat');
	});

	$('.book-online__inner').on('mouseenter', function () {

		if (!$('.book-online__title-img').hasClass('heartBeat')) {
			$('.book-online__title-img').addClass('heartBeat');
			$('.book-online__title-img').css("animation-delay", "0s");
		}
	});

	$('.left-search__logo').on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
		$(".SearchPanel a").addClass('yellowFlash');
	});

	const catLinks = Splitting({
		target: '.category-links',
		by: 'items',

	});

	$(".category-links").prepend('<div class="category-links__animation-trigger" data-sal=""></div>')

}

function preloadHoverImage() {
	var castleImageBg = new Image();
	castleImageBg.src = "https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/just-in-case/castle-panel-hover-2.gif"
}

$(document).ready(function () {

	const scrollAnimations = sal({
		threshold: 0.75,
	});

	preloadHoverImage();

	$('.textContent a').addClass("has-tooltip");

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	$('.category-links__link').prepend(star);
	$('.category-links__child-link').prepend(star);


	$('.header__logo').on('mouseenter', function () {
		$('.outer-wrapper__bg-inner').addClass("bgShift");

	});

	$('.header__logo').on('mouseleave', function () {
		$('.outer-wrapper__bg-inner').removeClass("bgShift");

	});

	// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
	$('.toplinks').on('show.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
		$("#TopLinksInner .dropdown-menu a").addClass("animated");
		window.scrollBy(0, 1);
	});

	// ADD SLIDEUP ANIMATION TO DROPDOWN //
	$('.toplinks').on('hide.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp();
	});

	$(".pageContent.textContent h1:first-of-type, #BodyContent h1:first-of-type").attr("data-sal", "");
	$(".category-title h2").attr("data-sal", "");



	var pageTitle = Splitting({
		target: ".pageContent.textContent h1:first-of-type, #BodyContent h1:first-of-type",
		whitespace: true,
	});

	var categoryTitle = Splitting({
		target: ".category-title h2",
		whitespace: true,
	});

	$(' .pageContent.textContent h1:first-of-type, #BodyContent h1:first-of-type, .category-title h2').each(function () {
		$(this).attr("data-text", $(this).text());
	});


	if ($('#HomepageMarker').length > 0) {

		const scrollAnimations = sal({
			threshold: 0.75,
		});
		$("#BodyContent h1:first-of-type .word:last-child .char:last-child").on("webkitAnimationEnd oanimationend msAnimationEnd animationend", function () {
			$(".left-search__logo").addClass("sal-animate slideInUp2").css({ "visibility": "visible", "animation-play-state": "running" });
		});

		$('.page-listings__title, .home-clients__title, .testimonials__title').each(function () {
			$(this).attr("data-text", $(this).text());
		});

		$(".RotatorTestimonialRating").html('<img src="https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/just-in-case/testimonial-stars@1x.png" srcset="https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/just-in-case/testimonial-stars@1x.png 1x, https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/just-in-case/testimonial-stars@2x.png 2x" class="testimonials__stars" alt="" />');


		function fixHeights() {
			$('.RotatorTestimonial').sameHeight();
			if (window.matchMedia('(min-width:768px)').matches) {
				$('.featured-categories__col').sameHeight();
			}
			else {
				$('.featured-categories__col').height("auto");
			}
		}

		setTimeout(fixHeights, 300);


		$(window).on('resize',
			function () {
				setTimeout(fixHeights, 300);
			});

		var testimonialAuthors = $(".RotatorTestimonialContent a[itemprop='author']");
		if (testimonialAuthors.length > 0) {
			$(".RotatorTestimonialContent a[itemprop='author']")[0].nextSibling.nodeValue = '';
			$(".RotatorTestimonialContent a[itemprop='author']").each(function () {
				$(this)[0].nextSibling.nodeValue = '';
			});
		}

		if (window.matchMedia('(min-width:768px)').matches) {
			homepageAnimations();

		} else {
			$('.header__logo, .outer-wrapper__bg').css('visibility', 'visible');
			$('.outer-wrapper__bg').css('opacity', '1');
		}
	} else {
		$('.header__logo, .outer-wrapper__bg').css('visibility', 'visible');
		$('.outer-wrapper__bg').css('opacity', '1');
		$(".left-search__logo").css({ "visibility": "visible", "animation-play-state": "running" });

	}

	scrollAnimations.update();

});